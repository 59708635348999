import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { Row, Col, Stack } from 'react-bootstrap';
import { Header, Footer } from 'components/theme';
import { Container, Button } from 'components/common';
import 'animate.css';
import element60 from 'assets/illustrations/Element60.png';
import element61 from 'assets/illustrations/Element61.png';
import element62 from 'assets/illustrations/Element62.png';
import fairies from 'assets/illustrations/FAIRIES1.svg';
import CookieConsent from 'react-cookie-consent';

import {
  AboutKundenWrapper,
  AboutWrapper,
  PrivatKundenWrapperDetails,
  HeroImageAbout,
  ImageBackgroundAbout,
  Section1About,
  Section2About,
  Section3About,
  Section4Wrapper,
  Section5Wrapper,
  Section4BackgroundAbout,
  Section5Background,
} from '../components/styles/styles';

export default function About() {
  function getContactForm() {
    const id = document.getElementById('kontaktButton');
    if (id) {
      document.getElementById('homeButton').click();
      setTimeout(function() {
        document.getElementById('kontaktButton').click();
      }, 0);
    }
  }
  return (
    <>
      <AboutKundenWrapper>
        <Header />
        <HeroImageAbout>
          <AboutWrapper as={Container} className="positionRelative">
            <PrivatKundenWrapperDetails>
              <Stack gap={3}>
                <Row className="animate__animated animate__slideInDown">
                  <Col>
                    <h4 className="title1About">ÜBER</h4>
                  </Col>
                </Row>
                <Row className="animate__animated animate__slideInDown">
                  <Col>
                    <h4 className="title2About">UNS</h4>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <a href="mailto:office@rd-hausfee.at">
                      <Button className="buttonAnfrageAbout">ANFRAGE</Button>
                    </a>
                    <Link to="/">
                      <Button className="buttonAngebot" onClick={getContactForm}>
                        ANGEBOT EINHOLEN
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Stack>
            </PrivatKundenWrapperDetails>
          </AboutWrapper>
        </HeroImageAbout>
        <AboutWrapper as={Container} className="positionRelative">
          <Section1About>
            <div className="sectionP1">
              <h2 className="dancingScript64About">Wer steckt eigentlich hinter RD-Hausfee?</h2>
              <p className="latoRegular36">
                Hausfee ist Ihr zuverlässiger Partner in Sachen Reinigung. Wir sind gründlich, zuverlässig, schnell,
                gewissenhaft, ordentlich, engagiert, hygienisch, detailverliebt und so weiter. Vor allem sind wir aber
                eines: humorvoll! Wir finden, dass es sich mit Spaß und Freude gleich viel besser arbeitet und vergessen
                niemals auf den menschlichen Aspekt unserer Tätigkeit. Wir bringen ihr Haus UND Sie zum Strahlen!
              </p>
            </div>
          </Section1About>
        </AboutWrapper>
        <ImageBackgroundAbout>
          <AboutWrapper as={Container} className="positionRelative">
            <Section2About>
              <Row>
                <Col xs="12">
                  <h3 className="dancingScript56">
                    Die Fee und <br /> ihre FeenhelferInnen.
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col md="6" xs="12">
                  <p className="latoRegular21White">
                    Als Team arbeiten wir daran Ihr Zuhause zu einem Ort voller Glück, Sicherheit und Wohlbefinden zu
                    machen. Eifrig kümmern wir uns um Ihre Immobilie und sind bringen dabei nicht nur Sauberkeit und
                    Ordnung
                  </p>
                </Col>
                <Col md="6" xs="12">
                  <p className="latoRegular21White">
                    sondern auch eine große Portion gute Laune und Menschlichkeit. Wir zaubern Ihnen ein Lächeln ins
                    Gesicht. Versprochen. Wir sind Ihre guten Feen!
                  </p>
                </Col>
              </Row>
            </Section2About>
          </AboutWrapper>
        </ImageBackgroundAbout>
        <div className="height1520">
          <AboutWrapper as={Container} className="positionRelative">
            <Section3About>
              <div className="boxAbout"></div>
              <Row>
                <Col md="6" sm="8" xs="12" lg="4" className="marginRight53 zIndex99">
                  <img className="element60" src={element60} alt="Cleaning gloves" />
                </Col>
                <Col md="6" xs="12" className="zIndex99">
                  <div>
                    <h3 className="latoRegular36">
                      Die Hausfee <br /> und Gründerin
                    </h3>
                    <p className="latoRegular21">
                      Mein Name ist Durdica Rasic und mit viel Liebe und Engagement führe ich mein Unternehmen
                      RD-Hausfee. Mit großem Einfühlungsvermögen, Geduld, Ordnungssinn und ehrlichem Engagement habe ich
                      meine berufliche Erfüllung gefunden. Ich bin Ihre Hausfee aus Leidenschaft!
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="marginBottom384">
                <Col md="6" xs="12" className="zIndex99">
                  <img className="element61" src={element61} alt="Cleaning team" />
                </Col>
                <Col md="6" xs="12" className="section3Right zIndex99">
                  <h3 className="latoRegular36">
                    Die <br /> FeenhelferInnen
                  </h3>
                  <p className="latoRegular21">
                    Weil sich so viel Feenzauber gar <br /> nicht alleine erledigen lässt, habe <br /> ich die besten
                    FeenhelferInnen der <br /> Welt an meiner Seite. Sie <br /> unterstützen Sie gewissenhaft und <br />{' '}
                    leidenschaftlich im Haushalt und <br /> täglichen Leben.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md="6" xs="12" className="zIndex99">
                  <h3 className="latoRegular36">
                    We are <br /> family.
                  </h3>
                  <p className="latoRegular21">
                    TEAM können wir gar nicht groß genug schreiben. Wir halten zusammen und unterstützen uns
                    gegenseitig. Und davon profitieren Sie! Da wir top organisiert sind, kommt es nicht zu Ausfällen
                    durch Krankheit und Sie müssen nie auf Ihre gute Fee verzichten.
                  </p>
                </Col>
                <Col md="6" xs="12" className="zIndex99">
                  <img className="element62" src={element62} alt="Hands" />
                </Col>
              </Row>
            </Section3About>
          </AboutWrapper>
        </div>
        <Section4BackgroundAbout>
          <AboutWrapper as={Container} className="paddingBottom53">
            <Section4Wrapper>
              <Stack>
                <div>
                  <Stack className="paddingTop160">
                    <div>
                      <h2 className="dancingScript64">
                        Wir erledigen unsere Arbeit nicht nur zuverlässig, sondern auch mit Freude.
                      </h2>
                      <p className="latoRegular36White">
                        Sie haben Fragen zu unseren Leistungen oder wünschen sich <br /> sofort eine gute Fee? <br />
                        Vereinbaren Sie noch heute ein kostenloses Erstgespräch!
                      </p>
                    </div>
                  </Stack>
                  <Stack className="positionRelative">
                    <Button className="buttonKontaktAbout" onClick={getContactForm}>
                      KONTAKT
                    </Button>
                    <img className="fairies1About" src={fairies} alt="Fairies" />
                  </Stack>
                </div>
              </Stack>
            </Section4Wrapper>
          </AboutWrapper>
        </Section4BackgroundAbout>
        <Section5Background>
          <Footer />
        </Section5Background>
      </AboutKundenWrapper>
      <CookieConsent
        location="bottom"
        buttonText="Ich stimme zu"
        cookieName="myAwesomeCookieName2"
        style={{ background: '#95c357' }}
        buttonStyle={{ color: '#95c357', fontSize: '13px', background: '#fff' }}
      >
        Diese Website verwendet Cookies – nähere Informationen dazu und zu Ihren Rechten als Benutzer finden Sie in
        unserer <a href="/impressum">Datenschutzerklärung</a> am Ende der Seite. Klicken Sie auf „Ich stimme zu“, um
        Cookies zu akzeptieren.
      </CookieConsent>
    </>
  );
}
